<template>
  <!--Start Shopping Cart Content-->

  <v-container fluid class="pa-0">
    <v-card color="transparent" elevation="0">
      <v-card-title class="px-2 pb-0 fixed-topf  v-app-bar--fixed">
        <div class="d-flex">
          <v-btn icon @click="backToShoppingCart()">
            <v-icon color="primary" size="26px"> mdi-arrow-left</v-icon>
          </v-btn>

          <span class="font-weight-medium text-h6 ml-4 primary--text">
            {{ $t("generic.lang_amountToPay") }} ({{ this.total | currency }})
          </span>
        </div>
      </v-card-title>
      <v-card-text class="pa-0">
        <div class="mx-6 mt-0 pt-0">
          <v-divider></v-divider>
        </div>

        <div class="text-end d-flex">
          <v-spacer></v-spacer>
          <div>
            <h6
              class="py-2 px-5 my-5 font-weight-bold tertiary lighten-3"
              style="border-top-left-radius: 50px; border-bottom-left-radius: 50px;"
            >
              {{ $t("generic.lang_tableNo") }} {{ this.table }}
            </h6>
          </div>
        </div>

        <div class="mx-6">
          <h6>{{ $t("generic.lang_couponCode") }}</h6>
          <div>
            <v-text-field
              :placeholder="$t('generic.lang_couponCode')"
              class="rounded-lg"
              readonly
              rounded
              filled
              :value="
                parseFloat(this.giftCard.balance) > 0
                  ? this.$options.filters.currency(this.giftCard.balance)
                  : this.$t('generic.lang_couponCode')
              "
              dense
              min-height="40px"
            >
              <v-btn
                v-if="!parseFloat(this.giftCard.balance) > 0"
                depressed
                to="/gift-card"
                slot="append"
                class="rounded-lg scan-btn"
                id="scan-btn"
                color="accent"
              >
                <v-icon>
                  mdi-qrcode-scan
                </v-icon>
              </v-btn>
              <v-btn
                v-else
                depressed
                @click="removeGiftCard"
                slot="append"
                dense
                id="scan-btn-clear"
                class="rounded-lg scan-btn"
                color="error"
              >
                <v-icon>
                  mdi-qrcode-remove
                </v-icon>
              </v-btn>
            </v-text-field>
            <!--        <v-btn to="/gift-card" color="accent" depressed class="rounded-lg text-capitalize" dark>
              Hinzufugen
            </v-btn>-->
          </div>

          <div v-if="this.payments && this.payments.length > 0">
            <h6>{{ this.selectPaymentMsg }}</h6>
            <!--<v-combobox
              v-model="select"
              :items="comboboxItems"
              class="rounded-lg"
              outlined
              dense
            ></v-combobox>-->
            <v-radio-group v-model="payment_type">
              <v-radio
                v-for="p in payments"
                :key="p.id"
                :label="$t(`generic.${p.id}`)"
                :value="p.id"
                color="primary"
              ></v-radio>
            </v-radio-group>
          </div>

          <!--start: customer information form-->
          <v-expand-transition
            v-if="this.payment_type === 'tablebee_secupay_active'"
            class="border"
          >
            <v-form
              ref="customer_info_form"
              lazy-validation
              class="py-3 rounded-xl px-3"
            >
              <v-row class="customer_info_form">
                <v-col class="pb-2 pt-6 text-center" cols="12">
                  <v-img
                    class="mx-auto"
                    width="60"
                    src="@/assets/sp-logo.png"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="customer.firstname"
                    :rules="[v => !!v]"
                    hide-details
                    dense
                    outlined
                    label="First Name"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="customer.lastname"
                    :rules="[v => !!v]"
                    hide-details
                    dense
                    outlined
                    label="Last Name"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="customer.email"
                    :rules="[v => !!v]"
                    hide-details
                    dense
                    outlined
                    label="Email"
                  />
                </v-col>

                <v-col cols="12">
                  <v-btn
                    v-if="this.payments && this.payments.length > 0"
                    :disabled="loading || this.payment_type === null"
                    :loading="loading"
                    @click="pay()"
                    block
                    large
                    color="#7ba02c"
                    depressed
                    class="my-6 white--text text-capitalize"
                  >
                    {{ $t("generic.lang_buyNow") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-expand-transition>
          <!--end: customer information form-->

          <!--start: order summary -->
          <div class="py-3">
            <!--            <h5>{{ $t("generic.lang_invoiceSum") }}</h5>-->

            <v-sheet color="#E9E9E9" class="px-3 py-6 rounded-xl">
              <div class="d-flex">
                <h5 class="grey--text">{{ $t("generic.lang_checkOutTax") }}</h5>
                <v-spacer />
                <h6 class="text-right grey--text">
                  {{ $t("generic.lang_taxIncluded") }}
                </h6>
              </div>

              <div class="d-flex mt-3">
                <h6 class="font-weight-bold primary--text">
                  {{ $t("generic.lang_total") }}
                </h6>
                <v-spacer />
                <h6 class="font-weight-bold primary--text">
                  {{ this.total | currency }}
                </h6>
              </div>
            </v-sheet>
          </div>
          <!--end: order summary -->

          <v-btn
            v-if="
              this.payments &&
                this.payment_type !== 'tablebee_secupay_active' &&
                this.payments.length > 0
            "
            :disabled="loading || this.payment_type === null"
            :loading="loading"
            @click="pay()"
            block
            x-large
            color="primary"
            depressed
            class="my-6 text-capitalize"
          >
            {{ $t("generic.lang_buyNow") }}
          </v-btn>
          <div class="text-center font-weight-bold py-4" v-else>
            {{ $t("generic.lang_no_payment_method") }}
          </div>
        </div>

        <!--Start Success-->
        <Success-dialog :dialog="this.dialog" />
        <!--End Success-->
      </v-card-text>
    </v-card>
  </v-container>
  <!--End Shopping Cart Content-->
</template>

<script>
/*import components*/
import SuccessDialog from "@/components/Success/SuccessDialog.vue";

/*import plugins*/
import { createNamespacedHelpers } from "vuex";
import ENDPOINTS from "@/plugins/axios/endpoints";

export default {
  components: { SuccessDialog },

  methods: {
    ...createNamespacedHelpers("Order").mapMutations(["waitApprove"]),
    removeGiftCard() {
      this.$store.commit("Order/setGiftCard", null);
      this.$forceUpdate();
    },
    scan() {
      this.$router.push("/gift-card");
    },
    redirectToSuccess() {
      this.dialog = true;
    },

    backToShoppingCart() {
      this.$router.push("/menu");
      //this.$router.go(-1);
    },
    /**
     * load created order details
     */
    getOrder(order_id) {
      return this.$axios
        .get(ENDPOINTS.SELF_SERVICE.ORDERS.GET + order_id)
        .then(res => {
          if (res.status === 200 && typeof res.data === "object") {
            /*localStorage.setItem("order", JSON.stringify(res.data));
                                      localStorage.setItem("ordered", JSON.stringify(res.data.items));*/
            /*this.$store.commit("Order/setOrder", res.data.data);
                        this.$store.commit("Order/setOrdered", res.data.data.items);*/
            this.$store.commit("Order/pushOrder", res.data.data);
            this.$store.commit("Order/clearCard");
          }
        })
        .catch(() => {
          this.$swal({
            title: this.$t("errors.lang_cant_create_order"),
            text: this.$t("errors.lang_try_again"),
            icon: "error"
          });
        });
    },
    /**
     * pay the order
     */
    pay() {
      if (
        this.$refs.customer_info_form &&
        !this.$refs.customer_info_form.validate()
      )
        return;
      this.loading = true;
      //prepare order request body
      let body = {
        table: {
          name: this.table,
          party: this.party,
          uuid: this.uuid
        },
        note: this.note,
        customer_id: null,
        openItems: this.card,
        items: this.ordered || [],
        giftCard: this.giftCard,
        payment_id: this.payment_type,
        ignorePayment: this.payment_type === "tablebee_pay_at_checkout_active"
      };

      //add customer info
      if (this.payment_type === "tablebee_secupay_active") {
        body.customer = { ...this.customer };
      }

      //attach the old order id to the request to ensure that we'll update it
      if (this.order && this.order.id !== undefined)
        body.order_id = this.order.id;

      this.$axios
        .post(ENDPOINTS.SELF_SERVICE.ORDERS.CREATE, body)
        .then(res => {
          //if ordering as group is enabled we have to indicate that this order is waiting to mark it as last order
          this.waitApprove(this.can_order_as_group);

          if (this.payment_type === "tablebee_pay_at_checkout_active") {
            this.$store.commit("Order/setUnpaidOrder", res.data.order_id);
            this.$store.commit("Order/clearCard");
            this.$router.push(`success/${res.data.order_id}?type=unpaid`);
            this.$forceUpdate();
          }
          //paypal
          else if (this.payment_type === "tablebee_paypal_active") {
            //todo: check if is paypal
            if (res.data.url !== null) {
              let a = document.createElement("a");
              a.href = res.data.url;
              a.click();
              a.remove();
            }
          }
          //secupay
          else if (this.payment_type === "tablebee_secupay_active") {
            if (res.data.url !== null) {
              let a = document.createElement("a");
              a.href = res.data.url;
              a.click();
              a.remove();
            }
          }
        })
        .catch(() => {
          this.$swal({
            title: this.$t("errors.lang_cant_create_order"),
            text: this.$t("errors.lang_try_again"),
            icon: "error"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    ...createNamespacedHelpers("Order").mapGetters([
      "total",
      "note",
      "card",
      "ordered",
      "giftCard",
      "order"
    ]),
    ...createNamespacedHelpers("Table").mapGetters(["table", "party", "uuid"]),
    ...createNamespacedHelpers("Config").mapGetters([
      "isPaypalConfigured",
      "payments",
      "can_order_as_group",
      "selectPaymentMsg"
    ])
  },
  mounted() {
    //init payment method
    if (Array.isArray(this.payments) && this.payments.length > 0)
      this.payment_type = this.payments[0].id;

    if (
      this.$route.query.paymentError === true ||
      Number(this.$route.query.paymentError)
    ) {
      this.$swal({
        title:
          this.$route.query.message || this.$t("errors.lang_cant_create_order"),
        text: this.$t("errors.lang_try_again"),
        icon: "error"
      });
      // this.$router.replace({'query': null});
    }
  },
  data() {
    return {
      dialog: false,
      payment_type: null,
      select: ["Paypal"],
      comboboxItems: ["Barzahlung", "Online-Zahlung"],
      loading: false,
      customer: {
        firstname: "",
        lastname: "",
        email: ""
      }
    };
  }
};
</script>

<style>
#scan-btn,
#scan-btn-clear {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  min-height: 40px !important;
}

label {
}

.customer_info_form {
  border: 2px dashed #7ba02c !important;
}
</style>
