<template>
  <!--That Success Page it's last page after confirmation all thing-->
  <v-dialog v-model="dialog" fullscreen hide-overlay>
    <!--transition="dialog-bottom-transition"-->

    <!--<template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on">
        Open Dialog
      </v-btn>
    </template>-->

    <v-container
      fluid
      grid-list-xs
      fill-height
      justify-center
      align-center
      text-center
      class="bg"
    >
      <v-card width="90%" color="white" elevation-5 class="rounded-xl pa-10">
        <div>
          <v-img src="@/assets/img/tick.png" width="70%" class="mb-8 mx-auto" />
        </div>

        <div>
          <h6 class="grey--text text--lighten-1">
            {{ $t("generic.lang_orderSuccessfull") }}
          </h6>
          <!--          <h2 class="py-4 font-weight-bold">{{new Date().toLocaleDateString()}}</h2>-->
          <h6 class="grey--text text--lighten-1">
            {{ $t("generic.lang_youOrderBeingPrepared") }}
          </h6>
        </div>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean
  },

  data() {
    return {};
  }
};
</script>
